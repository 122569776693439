import React from 'react';
import {Link} from 'react-router-dom';

import ally_4_image from '../../assets/frontend/images/ally_4.jpeg';
import yoga_class_image from '../../assets/frontend/images/yoga_class.jpg';
import ryt_200_image from '../../assets/frontend/images/ryt_200.jpg';

function About(){
	return(
		<div className="body-content bg-fff pt-70l-110m-50s pb-170l-150s">
		
			<div className="panel large pt-20l-10s">
				<div className="grid-x">
					<div className="large-12 medium-12 small-12 cell text-left">
						<div className="font-raleway font-size-20 font-weight-700 txt-333 uppercase bb1-333 pb-5">Testimonials</div>
					</div>
				</div>
			</div>

			<div className="panel large">
				<div className="grid-x">
					<div className="large-4 medium-6 small-12 cell plr-5l-0s mt-20">
						<div className="bg-avw-light-green p-20">
						<div className="font-raleway font-medium font-weight-700 txt-333 uppercase">Chantel Bourbin</div>
						<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per justify pt-20">
						I have been a student of Ally's for over 5 years now. When walking into Ally's class you have willingly signed up to challenge yourself in mind and body, it is "non negotiable!" She is yours and you are hers for the next hour (buckle up). Through her pin point cues, impeccable demonstrations, and constant encouragement Ally provides all the tools you need to push pass your threshold of self-limitation and leave her class feeling stronger than when you walked in. Each sequence is designed with intention which for me this is key. The sequence has to be intentional, safe and push me to my limit both mentally and physically, Ally is the teacher that successfully and safely accomplishes those key points every class. You will not find a more authentic teacher willing to bring you to the front stage of your own self limitations and also push you through them. Ally won't sugar coat anything in class or outside of class, I appreciate the genuine person, teacher, coach in Ally. I admire her stepping up to give her time and hold space routinely during this pandemic and prior to. Ally's class is one most love to hate!
						</div>
						<div className="text-center pt-20">
						<i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i>
						</div>
						</div>
					</div>
					<div className="large-4 medium-6 small-12 cell plr-5l-0s mt-20">
						<div className="bg-avw-light-green p-20">
						<div className="font-raleway font-medium font-weight-700 txt-333 uppercase">Jeff Narduzzi</div>
						<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per justify pt-20">
						Ally's online HIIT classes are the best available, anywhere. She combines great exercise routines with perfect explanations of how to do them, perfect demonstration, class to class variation, all while providing the energy, motivation, and tough love needed for you to get the most out of it physically and mentally. The accountability you feel toward max effort is just as high as if you're in the studio with her right there. Ally's classes saved me during the quarantine in a time where I was desperate for a homebased fitness routine that could rival the intensity of what I was getting at CorePower. Luckily, Ally enabled me to find this.
						</div>
						<div className="text-center pt-20">
						<i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i>
						</div>
						</div>
					</div>
					<div className="large-4 medium-6 small-12 cell plr-5l-0s mt-20">
						<div className="bg-avw-light-green p-20">
						<div className="font-raleway font-medium font-weight-700 txt-333 uppercase">Aubree Hoepper</div>
						<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per justify pt-20">
						Ally's classes are absolutely incredible time and time again. Each class is so intelligently sequenced and builds upon previous classes so you can truly see your growth both physically and mentally. She is so inspirational and provides upbeat tunes with motivational cues throughout the entire hour of class. Ally does an incredible job of pushing you past limits you never could have imagined overcoming. She does a wonderful job of providing low-impact and up-level options to serve students of varying fitness levels. She also provides great anatomical set-up and refinement cues so you can have an effective and safe practice. I truly look forward to every single one of her classes each week! Ally is an amazing human and an incredible fitness instructor. I highly recommend trying her classes if you haven't yet had the chance.
						</div>
						<div className="text-center pt-20">
						<i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i>
						</div>
						</div>
					</div>
					<div className="large-4 medium-6 small-12 cell plr-5l-0s mt-20">
						<div className="bg-avw-light-green p-20">
						<div className="font-raleway font-medium font-weight-700 txt-333 uppercase">Karen Nguyen</div>
						<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per justify pt-20">
						I have been practicing for almost 4 years, and of all my time on the mat, Ally's classes (both vinyasa and HIIT) have been the most inspirational. What initially motivated me to continue to come weekly was merely the development of physical strength, but little did I know that her classes became my salvation. Everything from her breathing cues, knowledge of the human anatomy and creativity was therapeutic to me. I continued to show up to my mat as an outlet to decompress from the distractions of life and to challenge myself mentally and physically. The high heat and humidity is also the added bonus.
						</div>
						<div className="text-center pt-20">
						<i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i>
						</div>
						</div>
					</div>
					<div className="large-4 medium-6 small-12 cell plr-5l-0s mt-20">
						<div className="bg-avw-light-green p-20">
						<div className="font-raleway font-medium font-weight-700 txt-333 uppercase">Tess Filling</div>
						<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per justify pt-20">
						Over the past two years I have had the pleasure of being in Ally's various sculpt and power vinyasa classes. She is a phenomenal instructor and champion for accountability. Her ability to create a place for self reflection using her own vulnerabilities as a starting point 
						is courageous and has consistently created a space for stubborn athletes like myself to stop, think, adapt for ourselves. Having an instructor that uses her own past injuries to empathize and coach students through their own physical limits is something I never knew I needed. This is not to put Ally up on a pedestal. She herself will be the first to outline her limitations because she not only acknowledges her own humanity, but celebrates areas of improvement and invites you to join in too. I feel privileged to join in practices led by Ally and highly recommend you join me and the many others who follow her.
						</div>
						<div className="text-center pt-20">
						<i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i>
						</div>
						</div>
					</div>
					<div className="large-4 medium-6 small-12 cell plr-5l-0s mt-20">
						<div className="bg-avw-light-green p-20">
						<div className="font-raleway font-medium font-weight-700 txt-333 uppercase">Jatan Badesha</div>
						<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per justify pt-20">
						Ally's energy is incomparable. The moment you start her class, you feel empowered. She is real! She knows exactly what's going through your head when you're pushing through that last burpee and wanna give up! Because she has been through it. And I love the instructor who can be real. There's always uplevels and down levels in her class... but she will encourage you to shoot for the uplevels because that's where growth happens and that's where you get stronger! Ally is a mind blowing motivational instructor/ guide/ friend. She's a magnet, once you take her class you're attached! What sets her apart from others is her invincible energy and determination to bring out the best in each of her students.
						</div>
						<div className="text-center pt-20">
						<i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i>
						</div>
						</div>
					</div>
					<div className="large-4 medium-6 small-12 cell plr-5l-0s mt-20">
						<div className="bg-avw-light-green p-20">
						<div className="font-raleway font-medium font-weight-700 txt-333 uppercase">Maya Rhodes</div>
						<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per justify pt-20">
						Ally the Queen is truly what I think of her. She is darn close to royalty. She is inspirational with the way she carries herself, motivating with her words, and always has a can do attitude. She makes you want to turn into a pretzel and if you can't she will let you it's ok and then shares her story on how she got there. Ally is on a different level of any instructor I've ever taken. She puts 110% of her heart in every class. You feel her energy and her positive vibes within her every move. Her insane strength makes you strive to be better not only with yoga but in life. Thank you Ally for the strength you give me.
						</div>
						<div className="text-center pt-20">
						<i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i>
						</div>
						</div>
					</div>
					<div className="large-4 medium-6 small-12 cell plr-5l-0s mt-20">
						<div className="bg-avw-light-green p-20">
						<div className="font-raleway font-medium font-weight-700 txt-333 uppercase">Rahman </div>
						<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per justify pt-20">
						I have been taking Ally's class for years and it's usually the highlight of my day. She makes her class as it should be: innovative, challenging, and caring with awesome tunes. Her sequences are set up to flow and not in random order regardless of whether its vinyasa flow or power sculpt. I love taking her classes online and at a studio. Thank you Ally for showing so much passion for what you do and caring about each and every yogi in your class. Namaste!
						</div>
						<div className="text-center pt-20">
						<i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i>
						</div>
						</div>
					</div>
					<div className="large-4 medium-6 small-12 cell plr-5l-0s mt-20">
						<div className="bg-avw-light-green p-20">
						<div className="font-raleway font-medium font-weight-700 txt-333 uppercase">Neel Vora</div>
						<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per justify pt-20">
						I have been taking classes with Ally for many years now (<span className="italic">first at the yoga studio and most recently online due to Shelter in Place</span>) and she has consistently been one of my favorite yoga instructors that has been able to challenge, push, and motivate me to reach new levels in my yoga practice. Not only does she have a positive and supportive attitude while teaching but her energy is unmatchable; you feel the passion from her when she teaches. It is crystal clear how much Ally loves yoga and how she wants to spread the benefits of yoga to others from all over. She will go the extra mile to ensure her students are able to practice regularly (<span className="italic">most recently hosted online yoga classes multiple times a week during Shelter in Place</span>) and is firmly committed to her practice. I am beyond grateful to have met such a pure, kind-hearted, caring person like Ally and look forward to growing my practice in her classes.
						</div>
						<div className="text-center pt-20">
						<i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i>
						</div>
						</div>
					</div>
					<div className="large-4 medium-6 small-12 cell plr-5l-0s mt-20">
						<div className="bg-avw-light-green p-20">
						<div className="font-raleway font-medium font-weight-700 txt-333 uppercase">Catherine Stevenson</div>
						<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per justify pt-20">
						Ally's class is my go to workout. Her class is meticulously crafted down to the muscle group, body movement, and second. She's mastered the balance of cardio calorie burn & tone in her HIIT style class. When you step on your mat, you know the high quality of the class you're going to get and it's up to you to push yourself. If you're looking for a kick a** workout to push your mind and body to the limits, look no further.
						</div>
						<div className="text-center pt-20">
						<i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i>
						</div>
						</div>
					</div>
					<div className="large-4 medium-6 small-12 cell plr-5l-0s mt-20">
						<div className="bg-avw-light-green p-20">
						<div className="font-raleway font-medium font-weight-700 txt-333 uppercase">Kim Pestano</div>
						<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per justify pt-20">
						I've always HATED working out. For me, it was boring, tedious, and didn't hold my interest. I tried running, the gym, Pilates, you name it-none of it spoke to me. And then CorePower came along, and with that, Ally. Ally's 4:30PM sculpt class was the hardest and most rewarding thing I've ever willingly put myself through. And I continue to show up again and again each week. So why her? Why did it change me from a fitness hater to a fitness lover? I realized what changed was the motivation. She motivated me to come to my mat and be a BADASS. She motivated me to show up. Every class she motivated me to push past my edge, push past what's comfortable, and to do what's RIGHT, NOT what's easy. Every time I took a class where she was a student, she pushed herself harder than anyone in the room, and she carries that energy into the classes she teaches. To see someone hold themselves to the same standard as their students is admirable. That's why I take her classes-there is no BS, just pure motivation and accountability. What more can you ask for in a coach?
						</div>
						<div className="text-center pt-20">
						<i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i><i className="fa fa-star txt-333"></i>
						</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	);
}

export default About;