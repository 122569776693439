import React from 'react';
import {Link} from 'react-router-dom';

import ally_4_image from '../../assets/frontend/images/ally_4.jpeg';
import yoga_class_image from '../../assets/frontend/images/yoga_class.jpg';
import ryt_200_image from '../../assets/frontend/images/ryt_200.jpg';

function About(){
	return(
		<div className="body-content bg-fff pt-70l-110m-50s pb-170l-150s">
		
			<div className="panel large pt-20l-10s">
				<div className="grid-x">
					<div className="large-12 medium-12 small-12 cell text-left">
						<div className="font-raleway font-size-20 font-weight-700 txt-333 uppercase bb1-333 pb-5">About</div>
					</div>
				</div>
			</div>

			<div className="panel large pt-20">
				<div className="grid-x bg-fafafa b1-ddd p-20l-10s">
					<div className="large-4 medium-5 small-12 cell hide-for-small-only">
						<div className="text-left pr-20 hide-for-small-only">
							<img src={ally_4_image} alt="ally 4" width="100%"/>
						</div>
					</div>
					<div className="large-8 medium-7 small-12 cell">
						<div className="font-raleway font-size-20 font-weight-700 txt-333 uppercase text-left-center-on-small">Ally's Background</div>
						<div className="text-center show-for-small-only pt-20">
							<img src={ally_4_image} alt="ally 4" width="100%"/>
						</div>
						<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per justify pt-20">
						Growing up, I played soccer, exclusively, year round, and at a very high level.
						I was raised in a very competitive and athletic household, but eventually tore my
						ACL twice before the age of 16 resulting in the end of my athletic career.
						I struggled for several years to find a way to stay active that I truly enjoyed and didn't feel monotonous.
						This eventually lead me to power yoga in 2012 while attending San Diego State University.
						I took one class and was hooked. By 2016, I had taken almost 1,000 classes and finally committed
						to intensive power yoga teacher training. I began my teaching journey immediately after.
						My first yoga love is power vinyasa, but I found my teacher voice and creativity while teaching yoga sculpt.
						My teaching style is rooted in improving both your physical and mental strength and mobility.
						</div>
					</div>
				</div>
			</div>

			<div className="panel large pt-20">
				<div className="grid-x bg-fafafa b1-ddd p-20l-10s">
					<div className="large-4 medium-5 small-12 cell hide-for-small-only">
						<div className="text-left-center-on-small pr-20 hide-for-small-only">
						<img src={yoga_class_image} alt="yoga class" width="100%"/>
						</div>
					</div>
					<div className="large-8 medium-7 small-12 cell">
						<div className="font-raleway font-size-20 font-weight-700 txt-333 uppercase text-left-center-on-small">What To Expect</div>
						<div className="text-center show-for-small-only pt-20">
							<img src={yoga_class_image} alt="yoga class" width="100%"/>
						</div>
						<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per justify pt-20">
						In my traditional yoga classes, expect physically invigorated, intelligently sequenced classes to
						encourage you to find your physical barriers and break past them. In my yoga sculpt classes, you'll
						be challenged with a HIIT style sequence fused with aspects of other types of fitness and functional movement.
						Show up on your mat to cultivate physical and mental self-discipline.
						</div>
					</div>
				</div>
			</div>

			<div className="panel large pt-20">
				<div className="grid-x bg-fafafa b1-ddd p-20l-10s">
					<div className="large-4 medium-5 small-12 cell hide-for-small-only">
						<div className="text-center pr-20 hide-for-small-only">
						<Link to="https://www.yogaalliance.org/TeacherPublicProfile?tid=222094" className="logo" target="_blank"><img src={ryt_200_image} alt="ryt 200" width="75%"/></Link>
						</div>
					</div>
					<div className="large-8 medium-7 small-12 cell">
						<div className="font-raleway font-size-20 font-weight-700 txt-333 uppercase text-left-center-on-small">Registered Yoga Teacher</div>
						<div className="text-center show-for-small-only pt-20">
							<Link to="https://www.yogaalliance.org/TeacherPublicProfile?tid=222094" className="logo" target="_blank"><img src={ryt_200_image} alt="ryt 200" width="50%"/></Link>
						</div>
					</div>
				</div>
			</div>

		</div>
	);
}

export default About;