import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import AxiosApiClient from '../utils/AxiosApiClient';
import LoadingSpinner from '../frontend/LoadingSpinner';

import swal from 'sweetalert';

const error_style = 'font-raleway font-small font-weight-500 txt-000 bg-light-red p-5';

function Dashboard(){

	const navHistory = useNavigate();

	// using hooks
    const [isLoading, setIsLoading] = React.useState(false);
	const [emailMarketingInput, setEmailMarketingInput] = useState({
		message: '',
		errorList: [],
		errorStyle: [],
	});
	
	const handleInput = (event) => {
		event.persist();

		setEmailMarketingInput({...emailMarketingInput, [event.target.name]: event.target.value });

		//remove the target error message no matter the new input, it will be validated on the server
		if (emailMarketingInput.errorList.hasOwnProperty(event.target.name)){
			delete emailMarketingInput.errorList[event.target.name];
			delete emailMarketingInput.errorStyle[event.target.name];
		}
	}


	const emailMarketingSubmit = (event) => {
		event.preventDefault();
		
		setIsLoading(true);
			
		//values sent to api
		const payload = {
			message: emailMarketingInput.message,
		}

		var auth_api = 'phpLaravel';
		if(localStorage.getItem('auth_api') && localStorage.getItem('auth_api') != ''){
			auth_api = localStorage.getItem('auth_api');
		}

        const fetchData = async () => {
            try {
              const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ apiBackend: auth_api, token: localStorage.getItem('auth_token') });
              await getBearerToken();

              const response = await makeRequestWithHeaders('post', '/api/email_marketing', payload, {});

              setApiData(response);
            } catch (error) {
              handleApiError(error);
            }
        };
      
        fetchData();
	}
    function setApiData(response){
		
		if(response && response.data){
			if(response.data.status === 200){//HTTP_OK
				console.log('emailMarketingSubmit: ',response.data.message);
				
				setEmailMarketingInput({...emailMarketingInput, message: '', errorList: [], errorStyle: [] });
				
				swal("Success",response.data.message + " total users = " + response.data.total_users + ", total emails sent = " + response.data.total_emails_sent,"success");
				
			}else if(response.data.status === 401){//HTTP_UNAUTHORIZED
				console.log('emailMarketingSubmit error: ',response.data.message + ' back-end api call error');
				
				swal("Warning",response.data.message,"warning");
				
			}else if(response.data.status === 404){//HTTP_NOT_FOUND
				console.log('emailMarketingSubmit error: ',response.data.message + ' back-end api call error');
				
				swal("Warning",response.data.message,"warning");

			}else if(response.data.status === 800){//HTTP_FORM_VALIDATION_FAILED
				
				var errorStyleTemp = JSON.parse(JSON.stringify(response.data.validation_errors));
				Object.keys(errorStyleTemp).map((key) => (
					errorStyleTemp[key] = error_style
				));
				
				//validation errors mapped to input fields
				setEmailMarketingInput({...emailMarketingInput, errorList: response.data.validation_errors, errorStyle: errorStyleTemp });
				
			}else{//more errors
			}
		}

		setIsLoading(false);
	}
    function handleApiError(error){
		console.log('logemailMarketingSubmitinSubmit error: ',error + ' back-end api call error');
					
		setIsLoading(false);

		swal("Error",error,"error");
    }

	return(
		<div className="body-content bg-fff pt-70l-110m-50s pb-170l-150s">
		
			<div className="panel medium pt-20">
				<div className="grid-x bg-fff b1-ddd p-20l-10s">
					<div className="large-12 medium-12 small-12 cell text-center">
					<div className="font-raleway font-size-20 font-weight-700 txt-333 underline uppercase">Message All Clients</div>
					<div className="font-raleway font-medium font-weight-500 txt-222 text-center p-10">Please provide your message.</div>
		
						<form onSubmit={emailMarketingSubmit}>
							<div className="grid-x">
								<div className="large-12 medium-12 small-12 cell text-left">
									<div className={emailMarketingInput.errorStyle.message}>{emailMarketingInput.errorList.message}</div>
									<div className="input-group">
										<textarea  name="message" onChange={handleInput} value={emailMarketingInput.message} className="input-group-field" placeholder="Message" />
									</div>
								</div>
								<div className="large-12 medium-12 small-12 cell text-right">
									<div className="clearfix">
										<button type="submit" className="button width-125px-100px right">Submit</button>
										{isLoading &&
											<span className="right pr-10">
												<LoadingSpinner paddingClass="none" sizeClass="none" />
											</span>
										}
									</div>
								</div>
							</div>
						</form>
		
					</div>
				</div>
			</div>

		</div>
	);
}

export default Dashboard;