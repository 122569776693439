import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';

import AuthUtility from './auth/AuthUtility';
import AxiosApiClient from '../utils/AxiosApiClient';
import LoadingSpinner from './LoadingSpinner';

import $ from "jquery";
import swal from 'sweetalert';

function Yoga(){

	const navHistory = useNavigate();
	
	//using hooks
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingForBooking, setIsLoadingForBooking] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
	const [groups, setGroups] = useState([]);
  
	// Initial call for user list items
	useEffect(() => {
		
		setIsLoading(true);
		
		if (isMounted) {
			
			console.log('[Yoga - useEffect] mounted');

			var auth_api = 'phpLaravel';
			if(localStorage.getItem('auth_api') && localStorage.getItem('auth_api') !== ''){
				auth_api = localStorage.getItem('auth_api');
			}

			const fetchData = async () => {
				try {
				const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ apiBackend: auth_api, token: localStorage.getItem('auth_token') });
				await getBearerToken();
				const response = await makeRequestWithHeaders('get', '/api/avw_classes/Yoga', null, {});

				setApiData(response);
				} catch (error) {
				handleApiError(error);
				}
			};
		
			fetchData();
			
		}else {
	      setIsMounted(true);
	    }
	    
	}, [isMounted]);

    function setApiData(response){
		
		if(response && response.data){

			if(response.data.status === 200){//HTTP_OK
				
				setGroups(response.data.avw_groups);
				
			}else if(response.data.status === 404){//HTTP_NOT_FOUND
				
			}else{//more errors
			}
		}

		setIsLoading(false);
	}
    function handleApiError(error){
		console.log('[Yoga - useEffect] error: ',error + ' back-end api call error');

		setIsLoading(false);
    }

    // Toggle group classes
    const handleToggleGroupClasses = (i) => {

		for (var j = 0; j < groups.length; j++){

			if(j !== i){
				hideGroupClasses(j);
			}
			
		}

		if($('.group_'+i+'_classes').hasClass('hide')){
			$('.group_'+i+'_classes').removeClass('hide');
			$('.group_'+i+'_classes_button').html('Close');

			$('#group_'+i).removeClass('bg-fafafa');
			$('#group_'+i).addClass('bg-avw-light-blue');
			$('#group_'+i).removeClass('b1-ddd');
			$('#group_'+i).addClass('b1-avw-blue');
		}else{
			hideGroupClasses(i);
		}
        // Remove focus from the button
        $('.group_'+i+'_classes_button').blur();
    };
	function hideGroupClasses(i){
		$('.group_'+i+'_classes').addClass('hide');
		$('.group_'+i+'_classes_button').html('Classes');

		$('#group_'+i).removeClass('bg-avw-light-blue');
		$('#group_'+i).addClass('bg-fafafa');
		$('#group_'+i).removeClass('b1-avw-blue');
		$('#group_'+i).addClass('b1-ddd');
	}

    const handleBookClass = (i,j) => {

		var group_random_id = groups[i].random_id;
		var group_title = groups[i].title;
		var class_random_id = groups[i].avw_classes[j].random_id;
		var class_title = groups[i].avw_classes[j].title;

		if(!AuthUtility.isLoggedIn()){
			AuthUtility.clearAuthData();

			swal("Info","Please login to book "+class_title,"info");
		}else if(!AuthUtility.isSubscribed()){
			
			//swal("Info","<div className='font-raleway font-medium font-weight-500 txt-222 text-left-center-on-smallx line-height-125per justify'>Please subscribe to book "+class_title+'. To subscribe navigate to your account page.</div>',"info");
			swal({
				title: "Subscribe to Book a Class",
				text: "Please subscribe to book "+class_title+ ". Subscription options are in your Account.",
				icon: "info",
				buttons: {
				  cancel: "Ok",
				  confirm: {
					text: "Go to Account",
					value: true,
				  },
				},
			  }).then((value) => {
				if (value) {
					navHistory('/member/account');
				}
			  });
		}else{
			
			$('#button_'+i+'_'+j).addClass('hide');
			$('#loading_spinner_'+i+'_'+j).removeClass('hide');
				
			//values sent to api
			const payload = {
				group_random_id: group_random_id,
				group_title: group_title,
				class_random_id: class_random_id,
				class_title: class_title,
			}

			var auth_api = 'phpLaravel';
			if(localStorage.getItem('auth_api') && localStorage.getItem('auth_api') != ''){
				auth_api = localStorage.getItem('auth_api');
			}

			const fetchDataBookClass = async () => {
				try {
					const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ apiBackend: auth_api, token: localStorage.getItem('auth_token') });
					await getBearerToken();
					const response = await makeRequestWithHeaders('post', '/api/book_class', payload, {});

					setApiDataBookClass(response, i, j);
				} catch (error) {
					handleApiErrorBookClass(error, i, j);
				}
			};
		
			fetchDataBookClass();
		}

	}
    function setApiDataBookClass(response, i, j){
		
		if(response && response.data){
			if(response.data.status === 200){//HTTP_OK
				console.log('handleBookClass: ',response.data.message);

				$('#button_'+i+'_'+j).addClass('disabled').html('Booked');

				swal("Success",response.data.message,"success");
				
			}else if(response.data.status === 401){//HTTP_UNAUTHORIZED
				console.log('handleBookClass error: ',response.data.message + ' back-end api call error');
		
				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
				
				swal("Warning",response.data.message,"warning");
				
			}else{//more errors
			}
		}

		$('#button_'+i+'_'+j).removeClass('hide');
		$('#loading_spinner_'+i+'_'+j).addClass('hide');
	}
    function handleApiErrorBookClass(error, i, j){
		console.log('handleBookClass error: ',error + ' back-end api call error');

		$('#button_'+i+'_'+j).removeClass('hide');
		$('#loading_spinner_'+i+'_'+j).addClass('hide');

		swal("Error",error,"error");
    }

	return(
		<div className="body-content bg-fff pt-70l-110m-50s pb-170l-150s">
		
			<div className="panel large pt-20l-10s">
				<div className="grid-x">
					<div className="large-12 medium-12 small-12 cell text-left">
						<div className="font-raleway font-size-20 font-weight-700 txt-333 uppercase bb1-333 pb-5">Yoga</div>
					</div>
				</div>
			</div>

			{isLoading ? (
			<LoadingSpinner paddingClass="pt-10" />
			) : (
				groups.length === 0 ? (
					<div className="panel large pt-20l-10s">
						<div className="grid-x bg-fafafa b1-ddd p-20l-10s">	
							<div className="large-12 medium-12 small-12 cell pl-10l-0s">
								<div className="font-raleway font-size-20 font-weight-700 txt-333 text-left-center-on-small uppercase">No Groups Exists.</div>
							</div>
						</div>
					</div>
				) : (
					groups.map((group_item, i) => (
					group_item.avw_classes.length > 0 ? (
						<div className="panel large pt-10">
							<div id={"group_"+i} className="grid-x bg-fafafa b1-ddd p-10">
								<div className="large-3 medium-3 small-12 cell hide-for-small-only">
									<div className="text-left pr-10 hide-for-small-only">
										<img src={group_item.image_url ? group_item.image_url : '/images/HITT_Placeholder.jpg'} alt="group image" width="100%" />
									</div>
								</div>
								<div className="large-7 medium-7 small-12 cell pl-10l-0s">
									<div className="font-raleway font-size-20 font-weight-700 txt-333 text-left-center-on-small uppercase">{group_item.title}</div>
									<div className="text-center show-for-small-only pt-20">
										<img src={group_item.image_url ? group_item.image_url : '/images/HITT_Placeholder.jpg'} alt="group image" width="75%"/>
									</div>
									<div className="font-raleway font-medium font-weight-500 txt-222 text-left-center-on-smallx line-height-125per justify pt-10">
									{group_item.description}
									</div>
								</div>
								<div className="large-2 medium-2 small-12 cell text-right-center-on-small">
									<div className="pt-20l-10s show-for-small-only"></div>
									<Link to="" onClick={() => handleToggleGroupClasses(i)} className={"button small uppercase group_"+i+"_classes_button"} >Classes</Link>
								</div>
								
								{group_item.avw_classes.length === 0 ? (
								<div className={"large-12 medium-12 small-12 cell text-left-center-on-small pt-20 group_"+i+"_classes hide"}>
									<span className="font-raleway font-weight-700 txt-333 uppercase text-center">No Classes Exists</span>
								</div>
								) : (
								<div className={"large-12 medium-12 small-12 cell group_"+i+"_classes bg-fff mt-10 p-10 b1-avw-blue scrollable hide"}>
								{group_item.avw_classes.map((class_item, j) => (
									<div className="grid-x mb-10 pb-10 bb1-ccc">
										<div className="large-3 medium-3 small-12 cell hide-for-small-only">
											<div className="text-left pr-10 hide-for-small-only">
												<img src={class_item.image_url} alt="class image" width="100%" />
											</div>
										</div>
										<div className="large-7 medium-6 small-12 cell pl-10l-0s">
											<div className="font-raleway font-medium font-weight-700 txt-333 text-left-center-on-small uppercase">{class_item.title}</div>
											<div className="text-center show-for-small-only pt-20">
												<img src={class_item.image_url} alt="class image" width="50%"/>
											</div>
											<div className="font-raleway font-medium font-weight-500 txt-222 text-left-center-on-smallx line-height-125per justify pt-10">
											{class_item.description}
											</div>
										</div>
										<div className="large-2 medium-3 small-12 cell text-right-center-on-small">
											<div className="pt-20l-10s show-for-small-only"></div>
											<div>
												<Link to="" onClick={() => handleBookClass(i,j)} className="button small uppercase" >Book</Link>
											</div>
										</div>
									</div>
								))}
								</div>
								)}
							</div>
						</div>
					) : (<></>)
					))
				)
			)
			}

		</div>
	);
}

export default Yoga;