import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';

import AxiosApiClient from '../../components/utils/AxiosApiClient';
import AuthUtility from '../frontend/auth/AuthUtility';
import LoadingSpinner from '../frontend/LoadingSpinner';
import useClassStatusUpdater from './ClassUtilities';

import $ from "jquery";
import swal from 'sweetalert';

function Booked(){

	const navHistory = useNavigate();
	
	//using hooks
    const [isLoading, setIsLoading] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
	const [userBookedClasses, setUserBookedClasses] = useState([]);
    const { handleUpdateUserClassStatus, isLoadingAtTop } = useClassStatusUpdater(setUserBookedClasses);

  
	const [isModalOpen, setModalOpen] = useState(false);
	const [modalContent, setModalContent] = useState('');

	// Initial call for user list items
	useEffect(() => {
	
		if(!AuthUtility.isLoggedIn()){
				
			//user not authenticated on server so remove from local storage
			AuthUtility.clearAuthData();
				
			navHistory('/');
		}
		
		setIsLoading(true);
		
		if (isMounted) {
			
			console.log('[Booked - useEffect] mounted');
			
			localStorage.removeItem('booked_user_class_id');
			localStorage.removeItem('booked_user_class_random_id');
			localStorage.removeItem('booked_group');
			localStorage.removeItem('booked_class');
			localStorage.removeItem('booked_class_recording_url');

			console.log('[Booked - useEffect] removed localStorage item: booked_user_class_id');
			console.log('[Booked - useEffect] removed localStorage item: booked_user_class_random_id');
			console.log('[Booked - useEffect] removed localStorage item: booked_group');
			console.log('[Booked - useEffect] removed localStorage item: booked_class');
			console.log('[Booked - useEffect] removed localStorage item: booked_class_recording_url');

			var auth_api = 'phpLaravel';
			if(localStorage.getItem('auth_api') && localStorage.getItem('auth_api') !== ''){
				auth_api = localStorage.getItem('auth_api');
			}

			const fetchDataUserBookedClasses = async () => {
				try {
					const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ apiBackend: auth_api, token: localStorage.getItem('auth_token') });
					await getBearerToken();
					const response = await makeRequestWithHeaders('get', '/api/user_booked_classes', null, {});

					setApiDataUserBookedClasses(response);
				} catch (error) {
					handleApiErrorUserBookedClasses(error);
				}
			};
		
			fetchDataUserBookedClasses();

		}else {
	      setIsMounted(true);
	    }
	    
	}, [isMounted]);

    function setApiDataUserBookedClasses(response){
		
		if(response && response.data){

			if(response.data.status === 200){//HTTP_OK
				
				setUserBookedClasses(response.data.user_booked_classes);

				if(response.data.payment_paid && !response.data.payment_paid.subscription_is_valid){
					AuthUtility.setSubscriptionData(response.data.payment_paid.plan, response.data.payment_paid.start_date, response.data.payment_paid.end_date, response.data.payment_paid.subscription_is_valid);
				}

				if(response.data.user_booked_classes && response.data.user_booked_classes.length > 0 && !AuthUtility.isSubscribed()){
		
					//swal("Info","<div className='font-raleway font-medium font-weight-500 txt-222 text-left-center-on-smallx line-height-125per justify'>Please subscribe to book "+class_title+'. To subscribe navigate to your account page.</div>',"info");
					swal({
						title: "Subscribe to View Booked Classes",
						text: "Please subscribe to view all your booked classes. Subscription options are in your Account.",
						icon: "info",
						buttons: {
							cancel: "Ok",
							confirm: {
								text: "Go to Account",
								value: true,
							},
						},
					}).then((value) => {
						if (value) {
							navHistory('/member/account');
						}
					});
				}
				
			}else if(response.data.status === 404){//HTTP_NOT_FOUND
				
			}else{//more errors
			}
		}

		setIsLoading(false);
	}
    function handleApiErrorUserBookedClasses(error){
		console.log('[Booked - useEffect] error: ',error + ' back-end api call error');

		setIsLoading(false);
    }
	
	const handleViewClass = (event, booked_user_class) => {
		event.preventDefault();

		if(!AuthUtility.isLoggedIn()){
			AuthUtility.clearAuthData();

			swal("Info","Please login to view the class","info");
		}else if(!AuthUtility.isSubscribed()){
			
			//swal("Info","<div className='font-raleway font-medium font-weight-500 txt-222 text-left-center-on-smallx line-height-125per justify'>Please subscribe to book "+class_title+'. To subscribe navigate to your account page.</div>',"info");
			swal({
				title: "View Class",
				text: "Please subscribe to view "+booked_user_class.avw_class.title+ ". Subscription options are in your Account.",
				icon: "info",
				buttons: {
				  cancel: "Ok",
				  confirm: {
					text: "Go to Account",
					value: true,
				  },
				},
			  }).then((value) => {
				if (value) {
					navHistory('/member/account');
				}
			  });
		}else{
			localStorage.setItem('booked_user_class_id', booked_user_class.id);
			localStorage.setItem('booked_user_class_random_id', booked_user_class.random_id);
			localStorage.setItem('booked_group_title', booked_user_class.avw_group.title);
			localStorage.setItem('booked_class_title', booked_user_class.avw_class.title);
			localStorage.setItem('booked_class_recording_url', booked_user_class.avw_class.recording_url);

			navHistory('/member/class_viewer');
		}
	};
	

	return(
		<div className="body-content bg-fff pt-70l-110m-50s pb-170l-150s">
		
			<div className="panel large pt-20l-10s">
				<div className="grid-x bb1-333 pb-5">
					<div className="large-11 medium-11 small-11 cell text-left">
						<div className="font-raleway font-size-20 font-weight-700 txt-333 uppercase">Booked Classes</div>
					</div>
					<div className="large-1 medium-1 small-1 cell text-right">
						{isLoadingAtTop &&
						<LoadingSpinner sizeClass="tiny" />
						}
					</div>
				</div>
			</div>

			{isLoading ? (
			<LoadingSpinner paddingClass="pt-10" />
			) : (
				!userBookedClasses || userBookedClasses.length === 0 ? (
					<div className="panel large pt-20l-10s">
						<div className="grid-x bg-fafafa b1-ddd p-20l-10s">	
							<div className="large-12 medium-12 small-12 cell pl-10l-0s">
								<div className="font-raleway font-medium font-weight-700 txt-333 text-left-center-on-small">No User Classes.</div>
							</div>
						</div>
					</div>
				) : (
					userBookedClasses.map((user_booked_class_item, i) => (
						<div className="panel large pt-10">
							<div id={"booked_class_"+i} className={user_booked_class_item.status == 'booked' ? "grid-x bg-fafafa b1-ddd p-10" : "grid-x bg-eee b1-ddd p-10"}>
								<div className="large-2 medium-3 small-2 cell">
									<div className="text-left pr-10">
										<img src={user_booked_class_item.avw_class.image_url ? user_booked_class_item.avw_class.image_url : '/images/HITT_Placeholder.jpg'} alt="group image" width="90%" />
									</div>
								</div>
								<div className="large-8 medium-7 small-8 cell pl-10l-0s">
									<div className="font-raleway font-medium font-weight-600 txt-333 text-left">
									{user_booked_class_item.avw_class.title + " - "  + user_booked_class_item.avw_group.title}
									</div>
									<div className="font-raleway font-standard font-weight-500 txt-333 text-left pt-5">
									{user_booked_class_item.status}&nbsp;&nbsp;{convertDateTimeToText(user_booked_class_item.updated_at)}
									{user_booked_class_item.status == 'booked' &&
									<span className="font-raleway font-standard font-weight-500 txt-333 text-left pt-5 pl-20">
									<Link to="#" onClick={(e) => handleUpdateUserClassStatus(e, user_booked_class_item, 'canceled')} className="" valign="top">Cancel</Link>
									</span>
									}
									</div>
								</div>
								<div className="large-2 medium-2 small-2 cell text-right">
								{user_booked_class_item.status == 'booked' &&
								<Link onClick={(e) => handleViewClass(e, user_booked_class_item)} className="button tiny p-10 uppercase" valign="top">view</Link>
								}
								{(user_booked_class_item.status == 'viewed' ||  user_booked_class_item.status == 'canceled') &&
								<Link onClick={(e) => handleUpdateUserClassStatus(e, user_booked_class_item, 'rebook')} className="button tiny p-10 uppercase" valign="top">rebook</Link>
								}
								</div>
							</div>
						</div>
					))
				)
			)
			}
      
			{/* Render the Modal component */}
			{/*
			<Modal isOpen={isModalOpen} someObject={bookedClass} htmlContent={modalContent} onDone={handleBookedClassViewed}></Modal>
			*/}
		</div>
	);
}

export default Booked;


function convertDateTimeToText(some_date_time){
	
	if(!some_date_time || some_date_time === ''){
		return '';
	}else{
		var date = new Date(some_date_time);
		
	    var day = date.getDate() < 10 ? '0'+date.getDate() : date.getDate();
	    var month = (date.getMonth()+1) < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1;
		var year = String(date.getFullYear()).slice(-2);
	
		return month + '/' + day + '/' + year;
	}
	
}


/*
	const handleUpdateUserClassStatus = (event, user_class, new_class_status) => {
		event.preventDefault();

		if(!AuthUtility.isLoggedIn()){
			AuthUtility.clearAuthData();

			swal("Info","Please login to book "+user_class.avw_class.title,"info");

			navHistory('/login');

		}else if(!AuthUtility.isSubscribed()){
			
			swal({
				title: "Subscribe to Book and Cancel a Class",
				text: "Please subscribe to book and cancel "+user_class.avw_class.title+ ". Subscription options are in your Account.",
				icon: "info",
				buttons: {
				  cancel: "Ok",
				  confirm: {
					text: "Go to Account",
					value: true,
				  },
				},
			  }).then((value) => {
				if (value) {
					navHistory('/member/account');
				}
			  });
		}else{
		
			setIsLoadingSmall(true);
				
			//values sent to api
			const payload = {
				user_class_random_id: user_class.random_id,
				new_class_status: new_class_status,
			}

			var auth_api = 'phpLaravel';
			if(localStorage.getItem('auth_api') && localStorage.getItem('auth_api') != ''){
				auth_api = localStorage.getItem('auth_api');
			}

			const fetchDataUpdateUserClassStatus = async () => {
				try {
					const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ apiBackend: auth_api, token: localStorage.getItem('auth_token') });
					await getBearerToken();
					const response = await makeRequestWithHeaders('post', '/api/update_user_class_status', payload, {});

					setApiDataUpdateUserClassStatus(response, user_class, new_class_status);

				} catch (error) {
					handleApiErrorUpdateUserClassStatus(error);
				}
			};
		
			fetchDataUpdateUserClassStatus();
		}

	}
    function setApiDataUpdateUserClassStatus(response, user_class, new_class_status){
		
		if(response && response.data){
			if(response.data.status === 200){//HTTP_OK
				console.log('handleUpdateUserClassStatus: ',response.data.message);

				if(new_class_status == 'canceled'){
					setUserBookedClasses(prevClasses => prevClasses.filter(c => c.random_id !== user_class.random_id));
				}else if(new_class_status == 'rebook'){
					setUserBookedClasses(prevClasses => [response.data.user_class, ...prevClasses]);
				}

				swal("Success",response.data.message,"success");
				
			}else if(response.data.status === 401){//HTTP_UNAUTHORIZED
				console.log('handleCancelClass error: ',response.data.message + ' back-end api call error');
		
				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
				
				swal("Warning",response.data.message,"warning");
				
			}else{//more errors
			}
		}

		setIsLoadingSmall(false);

	}
    function handleApiErrorUpdateUserClassStatus(error){
		console.log('handleUpdateUserClassStatus error: ',error + ' back-end api call error');

		setIsLoadingSmall(false);

		swal("Error",error,"error");
    }
	*/