import React, {useState} from 'react';
import {Link} from 'react-router-dom';

import swal from 'sweetalert';

import logo from '../../assets/frontend/images/logo.png';

function Footer(){
	
	var margin_bottom = '';
    if(localStorage.getItem('auth_users_first_name') && localStorage.getItem('auth_users_last_name') && localStorage.getItem('auth_email')
	&& localStorage.getItem('auth_token') && localStorage.getItem('auth_role')){
		margin_bottom = 'mb-110l-90s';
	}

	const showPrivacyPolicy = (event) => {
		event.preventDefault();
	
		//Type appropriate comment here, and begin script below
		swal({
			title: 'Privacy Policy',
			text: 'Do you understand the privacy policy?',
			html: true,
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: 'Yes, I understand the privacy policy!'
		});
	}
	
	const showTermsConditions = (event) => {
		event.preventDefault();
	
		//Type appropriate comment here, and begin script below
		swal({
			title: 'Terms & Conditions',
			text: 'Do you understand the terms & conditions?',
			html: true,
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: 'Yes, I understand the terms!'
		});
	}
	
	return(
		<div className="sticky-bottom">

			<div className="sticky footer ptb-10l-5s bg-ddd bt1-bbb">
				<div className="panel large">
					<div className="grid-x">
						<div className="large-12 medium-12 small-12 cell">
							<Link to="/" className="hover-opacity-50 hide-for-small-only">
								<span className="left">
									<img src={logo} alt="logo" width="55"/>
								</span>
								<span className="text-left pl-10 left">
									<div className="font-raleway font-xx-large font-weight-700 txt-333 uppercase pt-5 pb-3">ALLY VON WOLF</div>
									<div className="font-raleway font-large font-weight-600 txt-333 letter-spacing-0px uppercase">Join The Wolf Pack</div>
								</span>
							</Link>
							<Link to="/" className="hover-opacity-50 show-for-small-only">
								<span className="left">
									<img src={logo} alt="logo" width="40"/>
								</span>
								<span className="text-left pl-7 left">
									<div className="font-raleway font-size-20 font-weight-700 txt-333 uppercase pt-2 pb-3">ALLY VON WOLF</div>
									<div className="font-raleway font-standard font-weight-600 txt-333 uppercase">Join The Wolf Pack</div>
								</span>
							</Link>
						</div>
						
						<div className="large-12 medium-12 small-12 cell bt1-bbb bb1-bbb ptb-10l-5s mt-10l-5s">
							<div className="hide-for-small-only">
								<span className="pr-10"><Link to="/about" className="font-raleway font-small font-weight-600 letter-spacing-0px uppercase">ABOUT</Link></span>
								<span className="plr-10"><Link to="/contact" className="font-raleway font-small font-weight-600 letter-spacing-0px uppercase">CONTACT</Link></span>
								<span className="plr-10"><Link to="/help" className="font-raleway font-small font-weight-600 letter-spacing-0px uppercase">HELP</Link></span>
								<span className="plr-10"><Link to="#" className="font-raleway font-small font-weight-600 letter-spacing-0px uppercase" onClick={showTermsConditions}>Terms & Conditions</Link></span>
								<span className="plr-10"><Link to="#" className="font-raleway font-small font-weight-600 letter-spacing-0px uppercase" onClick={showPrivacyPolicy}>Privacy Policy</Link></span>
							</div>
							<div className="show-for-small-only">
								<span className="pr-5"><Link to="/about" className="font-raleway font-small font-weight-600 letter-spacing-0px uppercase">ABOUT</Link></span>
								<span className="plr-5"><Link to="/contact" className="font-raleway font-small font-weight-600 letter-spacing-0px uppercase">CONTACT</Link></span>
								<span className="plr-5"><Link to="/help" className="font-raleway font-small font-weight-600 letter-spacing-0px uppercase">HELP</Link></span>
								<span className="plr-5"><Link to="#" className="font-raleway font-small font-weight-600 letter-spacing-0px uppercase" onClick={showTermsConditions}>Terms</Link></span>
								<span className="pl-5"><Link to="#" className="font-raleway font-small font-weight-600 letter-spacing-0px uppercase" onClick={showPrivacyPolicy}>Privacy</Link></span>
							</div>
						</div>
						<div className="large-12 medium-12 small-12 cell pt-10l-5s">
							<span className="font-raleway font-standard font-weight-500 txt-333 uppercase pr-20">&copy;&nbsp;2020-2024</span>
							<span className="font-raleway font-standard font-weight-500 txt-333">Update: 03/20/2024</span>
						</div>
					</div>
				</div>
				<div className={margin_bottom}></div>
			</div>
			
		</div>
	)
	
}

export default Footer;